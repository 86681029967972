/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ng2-slim-loading-bar/style.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/themes/lara-light-indigo/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/@ctrl/ngx-emoji-mart/picker.css";
@import '@angular/cdk/overlay-prebuilt.css';

html,
body {
  height: 100%;
  width: 100% !important;
  // overflow-x: hidden !important;
  background-color: #262626;
  margin: 0;
}
:root {
  --blue-500: #2196f3;
  --yellow-500: #ffeb3b;
  --green-500: #4caf50;
  --blue-400: #42a5f5;
  --yellow-400: #ffee58;
  --green-400: #66bb6a;
  --text-color: #333333;
}
body {
  min-height: 100%;
  position: fixed;
  overscroll-behavior: contain;
}
 .p-dialog-header {
  background-color: #262626 !important;
  color: #ffffff !important;
  padding: 1rem !important; 
}

body .p-dialog .p-dialog-titlebar,
body .p-dialog .p-dialog-content,
body .p-dialog .p-dialog-footer {
  background-color: #262626;
  color: #dcdcdc;
  border: 1px solid #262626;
  //border-radius: 0.25rem;
}
body .p-dialog.p-dialog-header {
  background-color: #262626;
  color: #dcdcdc;
  border: 1px solid #262626;
  //border-radius: 0.25rem;
}
.p-sidebar-header,
.p-sidebar-footer {
  display: none !important;
}

.p-sidebar-content {
  padding: 0 !important;
}
/* Center all p-dialog components */


app-home-page {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
}

@media (max-width: 600px) {
  app-home-page {
    grid-template-columns: 1fr !important;
    grid-template-rows: 40px 1fr !important;
    justify-items: inherit !important;
  }
}

app-root {
  width: 100%;
  height: 100%;
 /* display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;*/
}

.p-dialog-mask.p-widget-overlay {
  z-index: 999999999999999;
}

.transparent-bg {
  padding: 3px 8px;
  background-color: #07060678; //#0504044a;
  border-radius: 15px;
}

.cursor {
  cursor: pointer;
}

#equalizer {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  filter: invert(1);
}

.dialog-input {
  background-color: #262626 !important;
  color: #bbb !important;
}

#bar1 {
  animation: bar1 1.2s infinite linear;
}

#bar2 {
  animation: bar2 0.8s infinite linear;
}

#bar3 {
  animation: bar3 1s infinite linear;
}

#bar4 {
  animation: bar4 0.7s infinite linear;
}

@keyframes bar1 {
  0% {
    height: 2px;
  }
  50% {
    height: 7px;
  }
  100% {
    height: 2px;
  }
}

@keyframes bar2 {
  0% {
    height: 5px;
  }
  40% {
    height: 1px;
  }
  80% {
    height: 7px;
  }
  100% {
    height: 5px;
  }
}

@keyframes bar3 {
  0% {
    height: 7px;
  }
  50% {
    height: 0;
  }
  100% {
    height: 7px;
  }
}

@keyframes bar4 {
  0% {
    height: 2px;
  }
  50% {
    height: 7px;
  }
  100% {
    height: 2px;
  }
}

.p-dialog {
  position: fixed !important;
  left: 50% !important;
  top: 35% !important; 
  transform: translate(-50%, -50%) !important;
  max-width: calc(100% - 40px) !important;
  box-shadow: 0 0 6px 0 rgb(125 125 125 / 40%) !important; 
  z-index: 10000 !important; 
}

.p-widget-overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.web-main-menu-bar-panel {
  display: block;
  width: 90%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
}

.mobile-main-menu-bar-panel {
  display: block;
  width: 100%;
  height: 50px;
  position: absolute;
  z-index: 1;
}

/*
router-outlet {
  display: block;
}
*/

body .p-scrollpanel-bar-y {
  width: 4px !important;
  top: 0;
}

body .p-scrollpanel-bar-x {
  width: 2px !important;
  top: 0;
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .web-main-menu-bar-panel {
    width: 100%;
  }
}

.popup {
  font-weight: bold;
}

.list-view small {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  display: inline-block;
  margin-top: 3px;
}

.chat-header {
  background-color: #262626;
  color: #fff;
  height: 60px;
  font-family: Segoe UI;
  font-size: 25px;
  float: left;
  padding: 15px 10px;
  width: 100%;
  position: initial;
}

.inputText {
  border: none;
  height: 40px;
  padding: 10px 10px;
  margin: 3px 0;
  display: inline-block;
  box-sizing: border-box !important;
  font-size: 14px;
  font-family: Segoe UI;
  border-bottom: 1px solid #bbb;
  width: calc(100% - 20px);
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .p-dialog {
    width: auto !important;// calc(100% - 40px) !important;
    left: initial !important;
    transform: translateY(-50%) !important;
    top: 50% !important;
    position: absolute !important;
  }

  .list-box {
    min-width: 100%;
    margin-left: 0px;
    margin-top: 0px;
    padding-top: 0;
  }

  .list-box .col-12 {
    padding: 0px;
  }

  .closs-btn {
    right: 15px;
  }

  .list-view small {
    width: 100%;
  }

/*
  body .p-sidebar-mask {
    top: 52px !important;
  }*/

  .menu-general-title {
    width: calc(100% - 30px) !important;
    padding: 10px 0 10px 0px !important;
  }
}

.menu-general-title {
  padding: 10px 0 10px 18px;
  width: calc(100% - 46px);
  display: inline-flex;
  line-height: 22px;
}

.state-text-shown {
  font-size: 20px;
  cursor: pointer;
  margin: 0 !important;
  font-family: sans-serif;
  font-weight: 400;
}

.hasSubMenu:before {
  content: "\f0da" !important;
  font: normal normal normal 14px/1 FontAwesome !important;
  color: #5c5c5c !important;
}

body .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
  margin-top: 10px;
}

body .p-panel .p-panel-content {
  border: none !important;
}

.p-dialog-draggable {
  margin: auto !important;
}

body .p-accordion .p-accordion-header {
  width: 95% !important;
  margin: 2px auto !important;
}

body .sub-menu .p-accordion .p-accordion-header {
  width: 100% !important;
}

body .menu-accordion-header .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
  position: absolute;
  right: 20px;
  top: 40%;
}

::ng-deep body #p-accordiontab-6 .p-accordion .p-accordion-header a .p-accordion-toggle-icon,
::ng-deep body #p-accordiontab-7 .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
  display: none;
}

body .p-accordion .p-accordion-content {
  border: none !important;
}

body
  .menu-accordion-header
  .p-accordion
  .p-accordion-header:not(.p-state-disabled).p-state-active
  a
  .p-accordion-toggle-icon {
  color: #848484;
}

body .menu-accordion-header .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active a {
  background-color: white; //#dbdbdb;
  color: #333333;
  border: none;
  box-shadow: none;
}

body .menu-accordion-header .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active:hover a {
  background-color: white; //#dbdbdb;
  color: #333333;
  border: none;
  box-shadow: none;
}

body .menu-accordion-header .p-accordion .p-accordion-header a {
  background-color: white;
  color: #333333;
  font-weight: 700;
  transition: background-color 0.2s;
  padding: 15px;
  border: none;
  border-bottom: 0.5px solid #eee;
  border-top: 0.5px solid #eee;
}

body .sub-menu .p-accordion .p-accordion-header a {
  padding: 0 !important;
  font-weight: 400 !important;
  border: none !important;
  height: 41px !important;
}

body .sub-menu .p-accordion .p-accordion-header:not(.p-state-active):not(.p-state-disabled):hover a {
  background-color: white;
  border: none;
}

body .sub-menu .p-accordion .p-accordion-header a:focus,
body .sub-menu .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active:focus a {
  border: none !important;
  box-shadow: none !important;
}

body .sub-menu .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
  top: 10%;
}

body .sub-menu .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active a .p-accordion-toggle-icon {
  color: #848484;
}

body .sub-menu .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active a {
  background-color: white; //#dbdbdb;
  color: #333333;
}

body .sub-menu .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active:hover a {
  background-color: white; //#dbdbdb;
  color: #333333;
}

/*
.contextMenuItemText {
  padding: 3px;
  border-radius: 0;
  cursor: pointer;
  margin: 0px 5px;
  font-weight: 700;
  font-size: 12px;
  font-family: Segoe UI;
}

.menuImgModal {
  cursor: pointer;
  float: left;
  width: 16px;
  margin-right: 7px;
  margin-top: 4px;
}*/

.fSize10 {
  font-size: 10px;
}

.segoueui {
  font-family: Segoe UI;
}

.bold {
  font-weight: bold;
}

.connection-problem {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 99999;
  text-align: center;
}

.connection-problem > span {
  color: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.button-disabled {
  pointer-events: none;
  opacity: 0.4;
}
.pagination-button-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.button-enabled {
  pointer-events: initial;
  opacity: 1;
}

 .p-dialog .p-dialog-header .p-dialog-title {
  font-size: 16px !important;
  font-weight: bold !important;
}

 .p-dialog .p-dialog-content {
  font-family: 'Segoe UI', sans-serif !important;
  font-size: 14px !important;
}
.p-button {
  font-size: 12px; 
  padding: 6px 12px;  
  height: 32px; 
  line-height: 1.4;  
}
 .p-button .pi {
  font-size: 16px;
}
 .p-button-secondary {
  padding: 6px 10px;
}
 .p-button-primary {
  padding: 6px 10px; 
}
 .p-button-raised {
  padding: 6px 12px; 
}
 .p-button .p-button-icon-left {
  margin-right: 5px;
}
.p-button-raised.ml-2 {
  margin-left: 5px;
}
 .p-confirm-dialog {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  max-width: 90% !important; 
  width: auto !important;
  min-width: 300px !important;
}

@media (max-width: 600px) {
  ::ng-deep .p-confirm-dialog {
      width: 90% !important; 
  }
}
.p-menu {
  left: unset !important;
  top: unset !important;
  transform-origin: center top !important; 
}

.p-menu-overlay {
  position: absolute !important;
  right: 0px !important;
  top: 80px !important;
}

.p-colorpicker-overlay-panel {
  position: absolute !important;
  right: 0px !important;
  top: 80px !important;
}
.p-tooltip .p-tooltip-text {
  font-size: 14px; 
  padding: 4px 8px;
}
